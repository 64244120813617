<template>
  <div class="divBox">
    <el-card class="mt14">
      <el-button
        type="primary"
        @click="add"
        size="mini"
        style="margin-bottom: 5px"
        >新增</el-button
      >
      <el-table
        :data="tableData.data"
        size="small"
        highlight-current-row
      >
         <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号"
        />

        <el-table-column prop="sellPrice" label="售价（元）" min-width="120" />
        <el-table-column
          prop="receivePrice"
          label="到账余额（元）"
          min-width="120"
        />
        <el-table-column prop="sort" label="排序" min-width="120" />
        <el-table-column
          prop="updateTime"
          label="最后编辑时间"
          min-width="120"
        />
        <el-table-column label="详情" min-width="80" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              @click="onEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              @click="onDel(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="售价:" prop="sellPrice">
          <el-input
            class="inputWidth"
            placeholder="请输入售价"
            v-model="form.sellPrice"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>

        <el-form-item label="到账余额:" prop="receivePrice">
          <el-input
            class="inputWidth"
            placeholder="请输入到账余额"
            v-model="form.receivePrice"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input
            class="inputWidth"
            size="mini"
            placeholder="请输入排序"
            v-model="form.sort"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="vLoading" @click="submitForm('form')">提交</el-button>
          <!-- <el-button @click="resetForm('form')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
// import {
//   expressCompanyList,
//   expressCompanyUpdate
// } from "@/api/system/config";
import {
  activityList,
  activityAdd,
  activityDel,
  activityView,
  activityUp,
} from "@/api/balance/balance";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { getIndex } from "@/utils/other";
export default {
  name: "banner",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      vLoading: false,
      listLoading:false,
      title: "新增",
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
      },
      storeList: {},
      form: {
        sellPrice: "",
        receivePrice: "",
        sort: "",
      },
      tableData: {
        data: [{}],
        total: 0,
      },
      dialogVisible: false,
      dialogVisibleLink: false,
      rules: {
        receivePrice: [{ required: true, message: "请输入", trigger: "blur" }],
        sellPrice: [{ required: true, message: "请输入", trigger: "blur" }],
        sort: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
    uploadDelete() {},
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadAfter(res, done) {
      done();
    },
    upload(e) {
      console.log("123", e);
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    openClick() {
      if (!this.form.storeId) {
        this.$message.error("请选择关联门店");
        return;
      }
      console.log("123", this.form.storeId);
      this.dialogVisibleLink = true;
    },
    selectChange() {
      console.log("1231");
      this.$forceUpdate();
    },
    getList() {
      this.listLoading = true;
      activityList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    onDel(id){
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        activityDel(id).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功');
            this.getList();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    storeChange() {
      this.form.aboutId = "";
      this.form.aboutName = "";
    },
    getGoodsItem(row) {
      this.form.aboutId = row.id;
      this.form.aboutName = row.name;
      this.dialogVisibleLink = false;
    },
    formSubmit() {},
    add() {
      this.title = "新增";
      this.dialogVisible = true;
    },
    // 编辑
    onEdit(row) {
      this.title = "编辑";
      activityView(row.id).then((res) => {
        if (res.code === 200) {
          this.form = res.data;
          this.dialogVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form.title = "";
      this.form.image = [];
      this.form.status = 0;
      this.dialogVisible = false;
    },
    handleCloseLink() {
      this.dialogVisibleLink = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            this.vLoading = true
            activityUp({
              ...this.form,
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.vLoading = false
                this.getList();
                this.handleClose();
                // done();
              } else {
                this.vLoading = false
                this.$message.error(res.msg);
                // done();
              }
            });
          } else {
            this.vLoading = true
            activityAdd({
              ...this.form,
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.vLoading = false
                this.getList();
                this.handleClose();
                // done();
              } else {
                this.$message.error(res.msg);
                this.vLoading = false
                // done();
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.changeLink {
  width: 200px;
  height: 30px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  font-size: 14px;
  padding-left: 14px;
  box-sizing: border-box;
  cursor: pointer;
}
.inputWidth {
  width: 300px;
}
.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
  